<template>
  <div class="sm:h-24 md:h-16 w-full bg-primary">

    <div class="w-3/4 h-full mx-auto flex sm:flex-col md:flex-row sm:justify-center md:justify-between items-center">
      <h1 class="text-white font-bold">Social Media</h1>
      <div>
        <h1 class="text-white font-bold">Contactez Nous : +216 22 222 222</h1>
      </div>
    </div>
  </div>

</template>

<script>
export default {};
</script>

<style>
</style>