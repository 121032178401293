<template>
  <div class="w-full  ">
    <h1 class="text-center pt-16 font-bold text-4xl text-primary">Hôtels en Tunisie</h1>

    <div class="flex overflow-x-scroll pb-10 no-scrollbar ">
      <div class="flex flex-nowrap ml-28 no-scrollbar ">

        <div v-for="hotel in hotels" :key="hotel" :class="`mx-2 w-80 my-5 rounded-xl shadow-lg h-96 hover:scale-105 transition-all duration-300 bg-cover bg-[url('${hotel.IMAGE}')]`">
          <div class="w-full h-full bg-gradient-to-t from-black flex flex-col justify-end">
            <div class="p-8">
              <h1 class="text-white font-bold text-2xl">{{hotel.HOTEL}}</h1>
              <h1 class="text-white font-bold">{{hotel.PRIX}} DT</h1>

              <h1 v-if="hotel.PROMO" class="py-1 w-fit text-white font-bold px-2 bg-red-500 rounded-lg">PROMO : {{hotel.PROMO}}</h1>
              <h1 class="text-white border-b-8 border-black hover:border-b-8 hover:border-white hover:font-bold transition-all duration-300 w-fit">Reserver Maintenant</h1>

            </div>

          </div>

        </div>

      </div>

    </div>

  </div>
</template>

<script>
export default {
    data(){
    return {
      hotels: [
        {
          IMAGE: "https://www.tnbooking.tn/images/hotels/40/ncja8zqq.jpg",
          HOTEL: "MARINA PALACE",
          CATEGORIE: "4****",
          PRIX: "42.000",
          ARRENGEMANT: "LPD",
        },
        {
          IMAGE:
            "https://static.freeoui.com/uploads/2021/07/Ut3qHngBWWNxtniEmRrZhenOT8Xs7zB8T6OHOoUT.png",
          HOTEL: "HAMMAMET REGENCY",
          CATEGORIE: "4****",
          PRIX: "38.000",
          ARRENGEMANT: "LPD",
        },
        {
          IMAGE:
            "https://dynamic-media-cdn.tripadvisor.com/media/photo-s/02/75/88/f2/filename-imgp2721-jpg.jpg?w=900&h=600&s=1",
          HOTEL: "Hammamet Garden Resort & Spa",
          CATEGORIE: "4****",
          PRIX: "45.000",
          ARRENGEMANT: "LPD",
        },
        {
          IMAGE:
            "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/1d/41/28/7b/sol-azur-beach-hotel.jpg?w=900&h=-1&s=1",
          HOTEL: "Sol Azur Beach Congres",
          CATEGORIE: "4****",
          PRIX: "62.000",
          ARRENGEMANT: "LPD",
        },
        {
          IMAGE:
            "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/17/a5/11/75/sunconnect-delfino-beach.jpg?w=900&h=-1&s=1",
          HOTEL: "Sunconnect Delfino Beach",
          CATEGORIE: "4****",
          PRIX: "72.000",
          ARRENGEMANT: "LPD",
        },
        {
          IMAGE:
            "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/15/63/0a/24/vincci-nozha-beach-spa.jpg?w=900&h=600&s=1",
          HOTEL: "Vincci Nozha Beach & Spa",
          CATEGORIE: "4****",
          PRIX: "76.000",
          ARRENGEMANT: "DP+",
        },
        {
          IMAGE:
            "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/15/31/54/8e/poolbar.jpg?w=900&h=600&s=1",
          HOTEL: "Paradis Palace",
          CATEGORIE: "4****",
          PRIX: "80.000",
          ARRENGEMANT: "DP",
        },
        {
          IMAGE:
            "https://media-cdn.tripadvisor.com/media/photo-s/0f/9e/2b/7a/holiday-village-manar.jpg",
          HOTEL: "Holiday Village Manar By Magic Life",
          CATEGORIE: "5*****",
          PRIX: "80.000",
          ARRENGEMANT: "LPD",
          PROMO: "30%",
        },
        {
          IMAGE:
            "https://cf.bstatic.com/xdata/images/hotel/max1024x768/18751940.jpg?k=43d1b15def7aff2d153bb633feaa4001da73d338a1fcc8ede88debd44c9f956a&o=&hp=1",
          HOTEL: "Alhambra Thalasso",
          CATEGORIE: "5*****",
          PRIX: "83.000",
          ARRENGEMANT: "LPD",
          PROMO: "15%",
        },
        {
          IMAGE:
            "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/21/5e/7e/0a/ompolo-ext.jpg?w=900&h=-1&s=1",
          HOTEL: "Concorde Marco Polo",
          CATEGORIE: "4****",
          PRIX: "90.000",
          ARRENGEMANT: "DP+",
          PROMO: "15%",
        },
        {
          IMAGE:
            "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/21/7b/ab/3e/iberostar-averroes.jpg?w=900&h=-1&s=1",
          HOTEL: "Iberostar Averroes",
          CATEGORIE: "4****",
          PRIX: "110.000",
          ARRENGEMANT: "DP",
          PROMO: "10%",
        },
        {
          IMAGE:
            "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/07/9f/66/6e/sentido-le-sultan.jpg?w=900&h=-1&s=1",
          HOTEL: "Le Sultan Hammamet",
          CATEGORIE: "4****",
          PRIX: "122.000",
          ARRENGEMANT: "LPD",
          PROMO: "10%",
        },
        {
          IMAGE:
            "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/19/3f/b8/6b/photo0jpg.jpg?w=900&h=-1&s=1",
          HOTEL: "Resort Golden Tulip Taj Sultan",
          CATEGORIE: "5*****",
          PRIX: "126.000",
          ARRENGEMANT: "LPD",
          PROMO: "15%",
        },
        {
          IMAGE:
            "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/17/2c/40/e1/club-overview.jpg?w=900&h=-1&s=1",
          HOTEL: "Tui Magic Life Africana",
          CATEGORIE: "5*****",
          PRIX: "127.000",
          ARRENGEMANT: "ALL IN",
          PROMO: "30%",
        },
        {
          IMAGE:
            "https://image.resabooking.com/images/hotel/Steigenberger_Marhaba_Thalasso_8.jpg",
          HOTEL: "Steigenberger Marhaba Thalasso",
          CATEGORIE: "5*****",
          PRIX: "138.000",
          ARRENGEMANT: "LPD",
        },
      ],
    };
    }

}
</script>

<style>
</style>