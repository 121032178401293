<template>
  <div class="sm:hidden md:block w-full mt-8 h-[600px] bg-[url('https://xn--rservation-b7a.tn/uploads/0000/1/2022/02/01/2.jpg')] bg-cover">
    <div class="w-full h-full bg-gradient-to-t from-primary">
      <div class="w-3/4 mx-auto h-full flex flex-col justify-end ">
        <div class="mb-32">
          <h1 class="font-bold text-7xl text-white">TravelPedia Agency</h1>
          <h1 class="font-bold text-xl text-white">Location : _______________</h1>

        </div>

      </div>
    </div>

  </div>
</template>

<script>
export default {};
</script>

<style>
</style>