<template>
  <div class="py-16 w-full">
    <div class="flex w-3/4 mx-auto flex-col justify-center items-center">
      <h1 class="font-bold text-2xl text-primary">Abonnez-vous à notre newsletter</h1>
      <h2 class="text-center pt-2">INSCRIVEZ-VOUS POUR RECEVOIR DES MISES À JOUR PAR E-MAIL SUR LES ANNONCES DE NOUVEAUX PRODUITS, LES IDÉES CADEAUX, LES PROMOTIONS SPÉCIALES, LES VENTES</h2>
      <div class="flex items-center justify-center w-3/4 mt-5 ">
        <input type="text" class="w-3/4  p-3 rounded-l  border-2 border-primary focus:outline-none focus:border-primary focus:border-l-8 transition-all duration-300" placeholder="Votre Email  ex:amine@gmail.com">

        <button>

          <h1 class="h-full p-[14px] rounded-r  bg-primary text-white">Abonnez Vous</h1>
        </button>

      </div>
    </div>

  </div>

</template>

<script>
export default {};
</script>

<style>
</style>