<template>
  <div class="w-11/12 mx-auto h-2/4 my-16">
      <div class="grid md:grid-cols-2 sm:grid-cols-1 h-full">
        <div class="h-full flex flex-col justify-center  w-3/4 mx-auto">
          <h1 class="font-bold text-2xl text-primary">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis, labore eum quidem reiciendis ratione fugit aut provident ullam est distinctio.
          </h1>
          <h2>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur repudiandae, ea veniam sed cumque, voluptatum cum, qui corporis voluptatem facilis quas sit repellendus. Modi illum laborum sequi consectetur suscipit quaerat nemo voluptatibus magnam culpa, harum atque consequatur aut dolore ratione neque facilis itaque laboriosam a! Expedita numquam porro sed nostrum!</h2>
          <div>
            <button class="px-5 py-2 text-white rounded-full bg-primary border-4 border-primary mt-5 hover:bg-transparent hover:border-primary hover:text-primary transition-all duration-300">consultez nos offres</button>
          </div>
        </div>
        <div>
          <img src="../../assets/HomePageImage.svg" width="600" class="mx-auto pt-8" alt="">

        </div>
      </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>