<template>
    
  <div class=" w-10/12 md:pt-5  mx-auto flex justify-between items-center ">

      <div class="sm:w-full md:w-fit">
        <img src="../../assets/logo.png" class="sm:mx-auto md:m-0 sm:w-32 md:w-24" width="75" alt="">

      </div>
      <div>
        <ul class="md:flex sm:hidden">
          <dropdown-menu>
            <li slot="trigger" class="px-2 text-primary font-bold text-md border-b-4 border-gray-50 hover:border-b-4 hover:border-primary transition-all duration-300">Reservation Hotels</li>
            <ul slot="body" class="w-full">
              <li class="text-center font-bold my-2">Hotel en Tunisie</li>
              <li class="text-center font-bold my-2">Hotel à l'etranger</li>
            </ul>
          </dropdown-menu>
          <dropdown-menu>
            <li slot="trigger" class="px-2 text-primary font-bold text-md border-b-4 border-gray-50 hover:border-b-4 hover:border-primary transition-all duration-300">Billetterie</li>
            <ul slot="body" class="w-full">
              <li class="text-center font-bold py-2 hover:bg-red-500">Billet d'avion</li>
              <li class="text-center font-bold py-2 hover:bg-red-500">Billet de bateaux</li>
            </ul>
          </dropdown-menu>

          <li class="px-2 text-primary font-bold text-md border-b-4 border-gray-50  hover:border-b-4 hover:border-primary transition-all duration-300">Voyages Organisés</li>

    
          <li class="px-2 text-primary font-bold text-md border-b-4 border-gray-50  hover:border-b-4 hover:border-primary transition-all duration-300">Omra</li>
          <li class="px-2 text-primary font-bold text-md border-b-4 border-gray-50  hover:border-b-4 hover:border-primary transition-all duration-300">Transfert et excursions</li>
          <li class="px-2 text-primary font-bold text-md border-b-4 border-gray-50  hover:border-b-4 hover:border-primary transition-all duration-300">Assurance Voyage</li>
        </ul>

      </div>

    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>