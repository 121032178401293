<template>
  <div class="w-full sm:h-full md:h-full bg-gray-50">
    <HeaderElement />
    <NavbarMenu />
    <Carousel />
    <About />

    <div class="w-full">
      <div class="w-11/12  mx-auto">
        <h1 class="text-center pt-16 font-bold text-4xl text-primary">Special Ramadan</h1>
        <div class="grid grid-cols-2 justify-items-center">

        <div v-for="event in ramadan" @click="handleElementClick(event.id)" :key="event" :class="`mx-2 w-5/6 my-5 rounded-xl shadow-lg h-96 hover:scale-105 transition-all duration-300 bg-cover bg-[url('${event.image}')]`">
          {{event.id}}
          <div class="w-full h-full bg-gradient-to-t from-black flex flex-col justify-end">
            <div class="p-8">
              <!-- <h1 class="text-white font-bold text-2xl">{{hotel.HOTEL}}</h1> -->
              <!-- <h1 class="text-white font-bold">{{hotel.PRIX}} DT</h1> -->

              <!-- <h1 v-if="hotel.PROMO" class="py-1 w-fit text-white font-bold px-2 bg-red-500 rounded-lg">PROMO : {{hotel.PROMO}}</h1> -->
              <h1 class="text-white border-b-8 border-black hover:border-b-8 hover:border-white hover:font-bold transition-all duration-300 w-fit">Reserver Maintenant</h1>

            </div>

          </div>
        </div>

        <vs-dialog overflow-hidden full-screen v-model="active">
        <template #header>
          <h4 class="not-margin">
            Special <b>Ramadan</b>
          </h4>
        </template>

        <!-- <h1 v-if="elemend">{{ramadan[elementId].image}}</h1> -->
        
        <div class="w-[600px] h-[600px] mx-auto">

        <div v-if="elementId != null" :class="`mx-auto w-full h-full bg-cover bg-[url('${ramadan[elementId].image}')]`"> 

        </div>

        </div>
        


      </vs-dialog>

        </div>
      </div>

    </div>

    <Hotels />

    <contact />
    <page-footer />

  </div>
</template>

<script>
import HeaderElement from "./Components/Header.vue";
import NavbarMenu from "./Components/NavbarMenu.vue";
import Carousel from "./Components/Carousel.vue";
import About from "./Components/About.vue";
import Contact from "./Components/contact.vue";
import PageFooter from "./Components/PageFooter.vue";
import Hotels from "./Components/Hotels.vue";
export default {
  components: {
    Carousel,
    HeaderElement,
    NavbarMenu,
    About,
    Contact,
    PageFooter,
    Hotels,
  },
  name: "Home",

  methods:{
    handleElementClick(id){
      console.log("test here")
      this.elementId = id;
      this.active = true;
    },
    
  },

  data() {
    return {
      elementId : null,
      active : false,
      ramadan: [
        {
          id : 0,
          image:
            "https://scontent.ftun14-1.fna.fbcdn.net/v/t39.30808-6/277000179_5079424412123931_2028887036909484516_n.png?_nc_cat=106&ccb=1-5&_nc_sid=730e14&_nc_ohc=URji98peCZ4AX_y_ywj&_nc_ht=scontent.ftun14-1.fna&oh=00_AT-oqjphsQmpbZBHIyPQQ1PaOuATtuSVP6IYgbuLZeyKbw&oe=6253519F",
        },
        {
          id : 1,
          image:
            "https://scontent.ftun14-1.fna.fbcdn.net/v/t39.30808-6/277131016_5079424328790606_2320888209387638107_n.png?_nc_cat=107&ccb=1-5&_nc_sid=730e14&_nc_ohc=mE6irRuHKdIAX9lNcum&_nc_ht=scontent.ftun14-1.fna&oh=00_AT8-iK7DWp2BAQDWd2HbwN1fEk2wZE-QEJkFy26cOMcEWQ&oe=6252DA4F",
        },
        {
          id : 2,
          image:
            "https://scontent.ftun14-1.fna.fbcdn.net/v/t39.30808-6/276207896_5079424248790614_6393496377088113605_n.png?_nc_cat=111&ccb=1-5&_nc_sid=730e14&_nc_ohc=piTwtdxhFS8AX9EqKtQ&_nc_ht=scontent.ftun14-1.fna&oh=00_AT_zYtAK78yDYt9BsF7Sx4qFfRwNM8TsdIqh86okZCCMyQ&oe=62523888",
        },
        {
          id : 3,
          image:
            "https://scontent.ftun14-1.fna.fbcdn.net/v/t39.30808-6/276260938_5079424392123933_7036576775584568361_n.png?_nc_cat=102&ccb=1-5&_nc_sid=730e14&_nc_ohc=x9RzIoLG-ZUAX8jM7fi&_nc_ht=scontent.ftun14-1.fna&oh=00_AT9MnWLFSNJ5RkEQRkd9ZKHGBAbJZC2NLIbi76-GaT23zA&oe=62537548",
        },
      ],
    };
  },
};
</script>

<style>
</style>
